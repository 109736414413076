import { createSlice } from "@reduxjs/toolkit";
import { createEncryptedAsyncThunk } from "../../Utility/APIUtils";
import _ from "lodash";

const initialState = {
  benDeleteSuccess: false,
  error: "",
  loading: false,
  creditAmountSucess: false,
  creditAmountResponse: {
    error: false,
    message: "",
  },
  commonDeleteResponse: {
    status: false,
    message: "",
  },
};

export const addNewBeneficiary = createEncryptedAsyncThunk(
  "beneficiary/addNewBeneficiary",
  "transaction/RegisterBeneficiary",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      // mobile: params.customerMobileNumber,
      // benename: params.accountHolderName,
      // bankid: `${params.selectBank.value}`,
      // accno: params.accountNumber,
      // ifsccode: params.IFSCcode,
      // verified: "0",
      // gst_state: "Maharshtra",
      // dob: "1992-04-05",
      // address: "AT-123",
      // pincode: "400706",

      userId: params.currentUser.userGuid,
      benemobile: params.customerMobileNumber,
      benename: params.accountHolderName,
      bankid: `${params.selectBank.value}`,
      accno: params.accountNumber,
      ifsccode: params.IFSCcode,
      verified: "0",
      dob: "1992-04-05",
      address: "AT-23",
      branch_address: "Thane, mumbai",
      pincode: "400706",
      serviceid: params.serviceId.serviceId,
      txnType: "IMPS",
      email: "example2gmail.com",
      customer_id: params.custId,
      beneficiaryVerified: params.beneficiaryVerified || false,
    }),
  }
);
export const addNewBeneficiarySelf = createEncryptedAsyncThunk(
  "beneficiary/addNewBeneficiarySelf",
  "account/CreateBenificiary",
  {
    prepareBody: (params) => ({
      remt_mobileno: params.Mobile.customerMobileNumber,
      cust_id: params.custId,
      userID: params.currentUser.userGuid,
      account_holder: params.beneBificiaryName,
      accountnumber: params.accountNumber,
      addresss: "At-123",
      ifsc: params.IfscCode,
      bank_id: Number(params.selectBank.value),
      bank_name: params.selectBank.label,
      branch_address: "At-123",
      mobileno: params.Mobile.customerMobileNumber,
      reference_benificiary_id: `${params.res.bene_id}`,
    }),
  }
);
export const addNewBeneficiarySelfIservue = createEncryptedAsyncThunk(
  "beneficiary/addNewBeneficiarySelfIservue",
  "transaction/RegisterBeneficiary",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      // userID: params.currentUser.userGuid,
      // remt_mobileno: params.Mobile.customerMobileNumber,
      // cust_id: params.custId,
      // account_holder: params.beneBificiaryName,
      // accountnumber: params.accountNumber,
      // addresss: "At-123",
      // ifsc: params.IfscCode,
      // bank_id: Number(params.selectBank.value1),
      // bank_name: params.selectBank.label,
      // branch_address: "At-123",
      // mobileno: params.Mobile.customerMobileNumber,
      // reference_benificiary_id: "",

      userId: params.currentUser.userGuid,
      benemobile: params.beneficiayMobile,
      benename: params.beneBificiaryName,
      bankid: params.selectBank.value,
      accno: params.accountNumber,
      ifsccode: params.IfscCode,
      verified: "0",
      dob: "1990-12-20",
      address: "At-123",
      branch_address: "At-123",
      pincode: "413604",
      serviceid: params.serviceId.serviceId,
      txnType: "IMPS",
      customer_id: params.custId,
    }),
  }
);
export const ValidateUserToken = createEncryptedAsyncThunk(
  "beneficiary/ValidateUserToken",
  "Token/ValidateUserToken",
  {
    transUrl: process.env.REACT_APP_API_TOKENSERVICE,
    prepareBody: (params) => ({
      token: params.Usertoken,
      userID: params.currentUser.userGuid,
    }),
  }
);
export const getServiceNotification = createEncryptedAsyncThunk(
  "beneficiary/getServiceNotification",
  "account/GetNotificationDetails",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      roles: params.roleId,
      serviceId: params.serviceId ? params.serviceId : [],
      city: [0],
      states: `${params.loginData.state_ID}`,
      languages: "",
      startDate: null,
      endDate: null,
    }),
  }
);
export const verifyBeneficiayForAdd = createEncryptedAsyncThunk(
  "beneficiary/verifyBeneficiayForAdd",
  "Modules/VerifyBankDetails",
  {
    prepareBody: (params) => ({
      ifsc: params.IfscCode,
      accountNumber: params.accountNumber,
      id: 0,
      beneficiary_Name: params.beneBificiaryName,
      iS_Verify: 0,
      userID: params.currentUser.userGuid,
    }),
  }
);
export const BeneficiaryDelete = createEncryptedAsyncThunk(
  "beneficiary/BeneficiaryDelete",
  "account/DeleteBeneficiary",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceId: Number(params.serviceId.serviceId),
      mobile: params.data.remt_mobileno,
      bene_id: `${params.data.id}`,
    }),
  }
);
export const verifyBeneficiayDetails = createEncryptedAsyncThunk(
  "beneficiary/verifyBeneficiayDetails",
  "transaction/VerifyBeneficiaryTransaction",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      // userID: params.currentUser.userGuid,
      // serviceId: params.serviceId.serviceId,
      // wallettype: 1,
      // bene_id: Number(params.body.benificiary_Id),
      userId: params.currentUser.userGuid,
      serviceId: params.serviceId.serviceId,
      wallettype: 1,
      beneficiary_ID: Number(params.body.id),
      agentid: "",
      senderMobileNumber: params.custMobile,
      username: "",
    }),
  }
);
export const verifyBeneficiaryByAccNo = createEncryptedAsyncThunk(
  "beneficiary/verifyBeneficiaryByAccNo",
  "account/VerifyBeneficiaryByAccNo",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userId: params.currentUser.userGuid,
      serviceId: params.serviceId.serviceId || 0,
      custId: params.custId || 0,
      wallettype: 1,
      ifsc: params.ifsc || "",
      accountNo: params.accountNo || "",
      mobileNo: params.mobileNo || "",
      bankId: params.bankId || 0,
    }),
  }
);
export const verifyBeneficiayDetailsForISRU = createEncryptedAsyncThunk(
  "beneficiary/verifyBeneficiayDetailsForISRU",
  "account/ISU_VerifyBeneficiaryTransaction",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceId: params.currentService.serviceId,
      wallettype: 1,
      beneficiary_ID: params.body.benificiary_Id, //Number(params.body.benificiary_Id),
      tpin: "1234",
      username: "", // params.body.account_holder,
    }),
  }
);
export const setOTP = createEncryptedAsyncThunk(
  "beneficiary/setOTP",
  "Service/TwoWayAuthentication",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      type: "MOBILE_VERIFICATION",
      mode: {
        mode1: "SMS",
        mode2: "",
      },
      auth_mode: 0,
      receiverDetails: {
        mobileNo: params.Mobile.customerMobileNumber,
        emailId: "",
      },
      ipaddress: params.location.ip,
      substitutes: {
        additionalProp1: "",
        additionalProp2: "",
        additionalProp3: "",
      },
    }),
  }
);
export const verifyOtp = createEncryptedAsyncThunk(
  "beneficiary/verifyOtp",
  "Service/VerifyPreUserOTP",
  {
    prepareBody: (params) => ({
      otp: params.otp,
      auth_mode: 1,
      type: "MOBILE_VERIFICATION",
      mode: {
        mode1: "SMS",
        mode2: "",
      },
      receiverDetails: {
        mobileNo: params.Mobile.customerMobileNumber,
        emailId: "",
      },
    }),
  }
);
export const customerAdd = createEncryptedAsyncThunk(
  "beneficiary/customerAdd",
  "transaction/CreateCustomer",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => {
      let body = {
        userID: params.currentUser.userGuid,
        customerMobileNo: params.customerMobileNumber,
        customerFirstName: params.fName,
        customerLastName: params.lName,
        dob: "1992-12-20",
        address: {
          country: "INDIA",
          city: "Mumbai",
          state: "Maharashtra",
          pincode: "413604",
          district: "Thane",
          area: "Thane",
        },

        email: "example@gmail.com",
        serviceId: params.serviceId.serviceId,
        // verification: {
        //   otpVerification: params.otpVerification || false,
        //   otp: "",
        // },
        // ekoCreateCustomer: {
        //   otp_ref_id: "",
        //   otp: "",
        //   skipverification: "true",
        // },
      };
      if (!_.isEmpty(params.serviceAPIData)) {
        body = {
          ...body,
          ...params.serviceAPIData,
        };
      } else {
        body = {
          ...body,
          verification: {
            otpVerification: params.otpVerification || false,
            otp: '',
          }
        }
      }
      console.log("body----", body);
      return body;
    },
  }
);
export const verifyOTPCustomer = createEncryptedAsyncThunk(
  "beneficiary/verifyOTPCustomer",
  "transaction/CreateCustomer",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => {
      let body = {
        userID: params.currentUser.userGuid,
        customerMobileNo: params.customerMobileNumber,
        customerFirstName: params.fName,
        customerLastName: params.lName,
        dob: "1992-12-20",
        address: {
          country: "INDIA",
          city: "Mumbai",
          state: "Maharashtra",
          pincode: "413604",
          district: "Thane",
          area: "Thane",
        },
        skipverification: "",
        email: "example@gmail.com",
        serviceId: params.serviceId.serviceId,
        // verification: {
        //   otpVerification: params.otpVerification || false,
        //   otp: params.otp,
        // },
      };
      if (!_.isEmpty(params.serviceAPIData)) {
        body = {
          ...body,
          ...params.serviceAPIData,
        };
      } else {
        body = {
          ...body,
          verification: {
            otpVerification: params.otpVerification || false,
            otp: params.otp,
          }
        }
      }
      console.log("body----", body);
      return body;
    },
  }
);
export const RegisterRemitter = createEncryptedAsyncThunk(
  "beneficiary/RegisterRemitter",
  "transaction/RegisterRemitter",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      mobile: params.Mobile.customerMobileNumber,
      firstname: params.fName,
      lastname: params.lName,
      address: params.address,
      otp: params.otp,
      pincode: params.pincode,
      stateresp: params.stateresp,
      bank3_flag: "No",
      dob: "1991-12-20",
      gst_state: `${params.selectState.value}`,
    }),
  }
);
export const getCustomerDetails = createEncryptedAsyncThunk(
  "beneficiary/getCustomerDetails",
  "account/GetCustomerList",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      cust_mobileNo: params.customerMobileNumber.customerMobileNumber,
      api_id: params.serviceId.api_id,
    }),
  }
);
export const getQuerryRemitter = createEncryptedAsyncThunk(
  "beneficiary/getQuerryRemitter",
  "transaction/QuerryRemitter",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceId: Number(params.serviceId.serviceId),
      mobile: `${params.customerMobileNumber}`,
      bank3_flag: "No",
      txnType: "IMPS",
    }),
  }
);
// export const getBeneficiaryList = createEncryptedAsyncThunk(
//   "beneficiary/getBeneficiaryList",
//   "transaction/FetchBeneficiary",
//   {
//     transUrl: process.env.REACT_APP_API_TRANSACTION,
//     prepareBody: (params) => ({
//       // userID: params.currentUser.userGuid,
//       // cust_id: params.customerData.cust_id,
//       userID: params.currentUser.userGuid,
//       mobile: params.beneData.mobileno,
//       serviceId: params.serviceId.serviceId,
//       custid: params.beneData.cust_id,
//       txn_type: "IMPS",
//     }),
//   }
// );
export const getBeneficiaryList = createEncryptedAsyncThunk(
  "beneficiary/getBeneficiaryList",
  "transaction/FetchBeneficiary",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      // userID: params.currentUser.userGuid,
      // cust_id: params.customerData.cust_id,
      userID: params.currentUser.userGuid,
      mobile: params.beneData.mobileno,
      serviceId: params.serviceId.serviceId,
      custid: params.beneData.cust_id,
      txn_type: "IMPS",
    }),
  }
);
export const benificiary = createSlice({
  name: "beneficiary",
  initialState,
  reducers: {
    creditAmountSuccess(state) {
      return { ...state, creditAmountSucess: true };
    },
    creditAmountFailed(state, action) {
      return {
        ...state,
        creditAmountResponse: { error: true, message: action.payload },
      };
    },
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return {
        ...state,
        rechargeResponse: { status: true, message: action.payload },
      };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, benDeleteSuccess: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        benDeleteSuccess: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        benDeleteSuccess: false,
        commonDeleteResponse: { status: false, message: "" },
      };
    },
  },
});
export const {
  FetchSuccess,
  FetchFailed,
  DeleteSuccess,
  DeleteFailed,
  DeleteInitial,
  creditAmountSuccess,
  creditAmountFailed,
} = benificiary.actions;
export default benificiary.reducer;
