import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import classNames from "classnames";
import { Spin } from "antd";
import Loader from "../components/common/loader";
import BreadCrumbs from "./breadcrumbs/BreadCrumbs";

function AdminPageLayout({
  children,
  loading,
  isBreadCrumbs = true,
  isButton = false,
  isLinkButton = false,
  buttonAction,
  buttonTitle = "",
  buttonLinkUrl = "",
  btnClass,
  extraContent,
  extraContentBefor,
  isPadding = true,
  isCardView = true,
  marqueeHeading
}) {
  
  return (
    <Spin spinning={loading} indicator={<Loader />} className="spinMain">
      <div>
        {marqueeHeading && marqueeHeading}
        <Row>
          {isBreadCrumbs && <Col md={isButton ? "6" : "12"}>
            <BreadCrumbs />
          </Col>}
          {isButton && (
            <Col md="6" className="mb-2 text-end">
              {extraContentBefor && extraContentBefor}
              {isLinkButton ? (
                <Link to={buttonLinkUrl}>
                  <Button className={classNames("btn", btnClass || 'btn-tab')}>
                    {buttonTitle}
                  </Button>
                </Link>
              ) : (
                <Button
                  className={classNames("btn", btnClass || 'btn-tab')}
                  onClick={buttonAction}
                >
                  {buttonTitle}
                </Button>
              )}
              {extraContent && extraContent}
            </Col>
          )}
        </Row>
        <Row>
          <Col lg="12">
          {isCardView ? <Card>
              <CardBody style={{ padding: isPadding ? "10px" : "0px" }}>
                {children}
              </CardBody>
            </Card> : <div className={classNames(isPadding ? "p-2" : 'p-0')}>
            {children}
              </div>}            
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default AdminPageLayout;
